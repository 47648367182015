import AboutComponent from '../components/about/about-component';
import { IAboutPage } from '../components/about/about.interface';
import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';
import { graphql } from 'gatsby';

interface Props {
  data: {
    allContentfulAboutPage: {
      nodes: IAboutPage[];
    };
  };
}

const About: React.FC<Props> = ({ data }: Props) => {
  const aboutPage: IAboutPage = data.allContentfulAboutPage.nodes[0];

  return (
    <StyledContent>
      <Seo
        title={'Om Mansted Wine'}
        image={'https://www.mansted-wine.dk/logo/mansted-wine-logo.jpg'}
        description={"Mansted wines fortrukne vinområder er vores egen baghave, det vil sige de forskellige lande i Europa. Jeg har både økologiske vine, og ikke økologiske vine."}
      />
      <AboutComponent aboutPage={aboutPage} />
    </StyledContent>

  );
};

export default About

export const AboutQuery = graphql`
  query {
    allContentfulAboutPage {
    nodes {
      title
      about {
        about
      }
      image {
          gatsbyImageData(width: 800, placeholder: BLURRED)
      }
    }
  }
  }
`;
