import { Col, Row } from 'antd';
import { H1Bold, H2Bold, TextSmall } from '../shared/typography';

import { GatsbyImage } from 'gatsby-plugin-image';
import { IAboutPage } from './about.interface';
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled';
import { theme } from '../shared/theme';
import useWindowWidth from '../shared/useWindowSize';

const TopContainer = styled.div`
  margin: 4rem auto;
  position: relative;
  width: 1000px;
  height: 1000px;
`
const StyledTopImg = styled(GatsbyImage)`
  width: 450px;
  height: 750px;
  `

const StyledTopDesc = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: white;
`

const StyledTopDescOut = styled.div`
  width: 550px;
  position: absolute;
  top: 3rem;
  right: 2rem;
  box-shadow: ${theme.effects.shadow01};
  z-index: 10;
`

const StyledTopHead = styled(H1Bold)`
  position: absolute;
  width: 500px;
  top: 1rem;
  right: 3rem;
  text-align: center;
  z-index: 15;
`

type Props = {
  aboutPage: IAboutPage
}

const AboutComponent: React.FC<Props> = ({aboutPage}: Props) => {
    const width = useWindowWidth();


    return (
      <div style={{marginTop: '2rem'}}>
      {width > 950 ? <TopContainer>
        <StyledTopDescOut><StyledTopDesc><ReactMarkdown>{aboutPage.about.about}</ReactMarkdown></StyledTopDesc></StyledTopDescOut>
        <StyledTopImg image={aboutPage.image.gatsbyImageData} alt={'om Mansted wine'} />
        <StyledTopHead>{aboutPage.title}</StyledTopHead>
      </TopContainer>
      :
      <Row justify={'center'}>
        <H2Bold style={{textAlign: 'center'}}>{aboutPage.title}</H2Bold>
        <Col xs={22} sm={22}><ReactMarkdown>{aboutPage.about.about}</ReactMarkdown></Col>
        <Col xs={22} sm={22}>
          <img alt="Mansted wine logo" src="/logo/mansted-wine-logo.jpg" style={{width: '100%', height: 'auto', marginTop: '2rem', borderRadius: '5px' }} /> 
        </Col>
      </Row>}
      </div>
    );
  };
  export default AboutComponent;